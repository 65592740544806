<template>
  <div class="">
    <el-card shadow="never">
      <el-button type="primary" @click="dialogVisible = true"
        >发布全景</el-button
      >
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column align="center" type="index" label="序号" />
          <el-table-column align="center" label="封面">
            <div slot-scope="scope">
              <img class="w-70 h-56 ra-4" :src="scope.row.thumb_path" alt="" />
            </div>
          </el-table-column>
          <el-table-column prop="name" align="center" label="项目标题">
            <dir slot-scope="scope">
              <p class="co-51 size-14 fw-5 txt-udl">{{ scope.row.name }}</p>
            </dir>
          </el-table-column>
          <el-table-column prop="atlasName" align="center" label="分类" />
          <el-table-column prop="a4" align="center" label="上传者" />
          <el-table-column
            prop="create_time"
            align="center"
            label="上传时间 "
          />
          <el-table-column
            prop="browsing_num"
            align="center"
            label="访问量PV  "
          />
          <el-table-column prop="a7" align="center" label="访问状态">
            <div slot-scope="scope">
              <el-switch
                v-model="scope.row.a7"
              >
              </el-switch>
            </div>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <div slot-scope="scope">
              <el-button type="text" @click="openModel(scope, 1)"
                >预览</el-button
              >
              <el-button type="text" @click="openModel(scope, 2)"
                >编辑</el-button
              >
              <el-button type="text">删除</el-button>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      title="上传全景图"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="ruleForm.options"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="title">
          <el-select
            v-model="ruleForm.options"
            placeholder="请选择分类"
            style="width: 100%"
          >
            <el-option
              v-for="item in selectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="全景图" prop="title">
          <el-upload
            class="upload-demo"
            drag
            :headers="headers1"
            action="http://720.jquen.com/api.php?module=upload"
            :data="headers"
            :on-success="handleAvatarSuccessCoverImg"
            multiple
            :file-list="fileList"
            :on-change="handleChangeZip"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              拖拽一组/单幅图片或<span class="co-51 pointer txy-udl"
                >点击上传</span
              >
            </div>
            <div class="el-upload__tip" slot="tip">
              *格式png、jpg，单张大小不超过2MB。
            </div>
            <el-button class="upload-btn" type="primary"
              >从素材库添加</el-button
            >
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { ringListData, modulePic } from "@/api/panorama";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      headers: {
        phone: "15890881767",
      },
      headers1: {
        method: "post",
      },
      dialogVisible: false,
      ruleForm: {
        name: "",
        options: "",
        coverImg: "",
      },
      rules: {},
      selectList: [
        {
          value: "选项1",
          label: "分类一",
        },
        {
          value: "选项2",
          label: "分类二",
        },
      ],
      value: "",
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      fileList: [],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    submit() {
      console.log(this.fileList, "fileList99");
      let data = {
        phone: "15890881763",
        file: this.fileList,
      };
      modulePic(data).then((res) => {});
    },
    //上传文件事件
    handleChangeZip(file, fileList) {
      if (file.status != "ready") {
        return false;
      }
      setTimeout(() => {
        this.fileList = fileList;
      }, 1000);
    },
    getdata() {
      ringListData({
        phone: "15890881763",
        page: this.currentPage,
      }).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.itemCount*1;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleAvatarSuccessCoverImg(res, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.fileList = fileList;
      console.log(this.fileList, "fileList22");
      // this.ruleForm.coverImg = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.coverImg = "";
    },
    openModel(item, i) {
      if (i == 1) {
      } else if (i == 2) {
        this.$router.push({ path: "/panoramaEdit", query: { setid: 123456 } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;

    .upload-btn {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
}
</style>