import qs from 'querystring'

export default class phpApi {
    static post(url, params) {
        return new Promise((resolve, reject) => {
            //  1.创建对象
            const xhr = new XMLHttpRequest() || new ActiveXObject("Microsoft.XMLHTTP");
            xhr.responseType = 'json';
            // 2.初始化设置请求方法(类型)和url
            xhr.open("post", "https://720.jquen.com/" + url);
            xhr.setRequestHeader(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            // 3.发送
            let data = qs.stringify(params);
            xhr.send(data);
            // 4.绑定事件，onreadystatechange,存储函数（或函数名）处理响应结果(每当 readyState 改变时，就会触发 onreadystatechange 事件,一共会触发 4 次,从 0 到 4)
            // readyState 属性存有 XMLHttpRequest 的状态信息
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status == 200) {
                        // let res = JSON.parse(xhr.response)
                        resolve(xhr.response);
                    } else {
                        reject(xhr.status);
                    }
                }
            }
        })
    }
    static phpUploadImg(url, params) {
        return new Promise((resolve, reject) => {
            //  1.创建对象
            const xhr = new XMLHttpRequest() || new ActiveXObject("Microsoft.XMLHTTP");
            xhr.responseType = 'json';
            // 2.初始化设置请求方法(类型)和url
            xhr.open("post", "http://720.jquen.com" + url);
            xhr.setRequestHeader(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            // 3.发送
            let data = qs.stringify(params);
            xhr.send(data);
            // 4.绑定事件，onreadystatechange,存储函数（或函数名）处理响应结果(每当 readyState 改变时，就会触发 onreadystatechange 事件,一共会触发 4 次,从 0 到 4)
            // readyState 属性存有 XMLHttpRequest 的状态信息
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status == 200) {
                        // let res = JSON.parse(xhr.response)
                        resolve(xhr.response);
                    } else {
                        reject(xhr.status);
                    }
                }
            }
        })
    }
    static post1(url, params) {
        return new Promise((resolve, reject) => {
            //  1.创建对象
            const xhr = new XMLHttpRequest() || new ActiveXObject("Microsoft.XMLHTTP");
            xhr.responseType = 'json';
            // 2.初始化设置请求方法(类型)和url
            xhr.open("post", "https://api.upload.jquen.com" + url);
            xhr.setRequestHeader(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            // 3.发送
            let data = qs.stringify(params);
            xhr.send(data);
            // 4.绑定事件，onreadystatechange,存储函数（或函数名）处理响应结果(每当 readyState 改变时，就会触发 onreadystatechange 事件,一共会触发 4 次,从 0 到 4)
            // readyState 属性存有 XMLHttpRequest 的状态信息
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status == 200) {
                        // let res = JSON.parse(xhr.response)
                        resolve(xhr.response);
                    } else {
                        reject(xhr.status);
                    }
                }
            }
        })
    }

    static deletedZip(url, params) {
        return new Promise((resolve, reject) => {
            //  1.创建对象
            const xhr = new XMLHttpRequest() || new ActiveXObject("Microsoft.XMLHTTP");
            xhr.responseType = 'json';
            // 2.初始化设置请求方法(类型)和url
            xhr.open("post", "https://api-t-2.upload.jquen.com" + url);
            xhr.setRequestHeader(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            // 3.发送
            let data = qs.stringify(params);
            xhr.send(data);
            // 4.绑定事件，onreadystatechange,存储函数（或函数名）处理响应结果(每当 readyState 改变时，就会触发 onreadystatechange 事件,一共会触发 4 次,从 0 到 4)
            // readyState 属性存有 XMLHttpRequest 的状态信息
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status == 200) {
                        // let res = JSON.parse(xhr.response)
                        resolve(xhr.response);
                    } else {
                        reject(xhr.status);
                    }
                }
            }
        })
    }

    static getIframeImg(url, params) {
        return new Promise((resolve, reject) => {
            //  1.创建对象
            const xhr = new XMLHttpRequest() || new ActiveXObject("Microsoft.XMLHTTP");
            xhr.responseType = 'json';
            // 2.初始化设置请求方法(类型)和url
            xhr.open("get", "https://api.upload.jquen.com" + url +'?token='+localStorage.getItem('token'));
            xhr.setRequestHeader(
                "Content-Type","application/x-www-form-urlencoded"
            );
            // 3.发送
            // let data = qs.stringify(params);
            xhr.send();
            // 4.绑定事件，onreadystatechange,存储函数（或函数名）处理响应结果(每当 readyState 改变时，就会触发 onreadystatechange 事件,一共会触发 4 次,从 0 到 4)
            // readyState 属性存有 XMLHttpRequest 的状态信息
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status == 200) {
                        // let res = JSON.parse(xhr.response)
                        resolve(xhr.response);
                    } else {
                        reject(xhr.status);
                    }
                }
            }
        })
    }

    static uploadImg(url, params) {
        return new Promise((resolve, reject) => {
            //  1.创建对象
            const xhr = new XMLHttpRequest() || new ActiveXObject("Microsoft.XMLHTTP");
            xhr.responseType = 'json';
            // 2.初始化设置请求方法(类型)和url
            xhr.open("post", "/api" + url );
            console.log('/api'+url);
            // xhr.setRequestHeader(
            //     "Content-Type","multipart/form-data"
            // );
            // 3.发送
            // let data = qs.stringify(params);
            xhr.send(params);
            // 4.绑定事件，onreadystatechange,存储函数（或函数名）处理响应结果(每当 readyState 改变时，就会触发 onreadystatechange 事件,一共会触发 4 次,从 0 到 4)
            // readyState 属性存有 XMLHttpRequest 的状态信息
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status == 200) {
                        // let res = JSON.parse(xhr.response)
                        resolve(xhr.response);
                    } else {
                        reject(xhr.status);
                    }
                }
            }
        })
    }
}