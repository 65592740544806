<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全景项目" name="first">
        <Tabs1 v-if="activeName == 'first'"></Tabs1>
      </el-tab-pane>
      <el-tab-pane label="全景分类" name="second">
        <Tabs2 v-if="activeName == 'second'"></Tabs2>
      </el-tab-pane>
      <el-tab-pane label="全景视频" name="three">
        <Tabs3 v-if="activeName == 'three'"></Tabs3>
      </el-tab-pane>
      <el-tab-pane label="360°环视" name="four">
        <Tabs4 v-if="activeName == 'four'"></Tabs4>
      </el-tab-pane>
      <el-tab-pane label="素材管理" name="five">
        <Tabs5 v-if="activeName == 'five'"></Tabs5>
      </el-tab-pane>
      <el-tab-pane label="离线下载" name="six">
        <!-- <Tabs5 v-if="activeName == 'six'"></Tabs5> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Tabs1 from "./tabs1.vue";
import Tabs2 from "./tabs2.vue";
import Tabs3 from "../video/index.vue";
import Tabs4 from "../360Ring/index.vue";
import Tabs5 from "../atlas/tabs1.vue";
export default {
  data() {
    return {
      activeName: "first", //tabs页
    };
  },
  components: {
    Tabs1,
    Tabs2,
    Tabs3,
    Tabs4,
    Tabs5,
  },
  filters: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
