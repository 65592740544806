<template>
  <div class="">
    <el-card shadow="never">
      <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
        <template v-slot:table>
          <el-table-column align="center" type="index" label="序号" />
          <el-table-column prop="userName" align="center" label="封面" />
          <el-table-column prop="userName" align="center" label="标题" />
          <el-table-column prop="userName" align="center" label="上传者" />
          <el-table-column prop="userName" align="center" label="上传时间" />
          <el-table-column prop="userName" align="center" label="播放量PV " />
          <el-table-column prop="userName" align="center" label="图片数量 " />
          <el-table-column prop="userName" align="center" label="标签" />
          <el-table-column prop="userName" align="center" label="排序" />
          <el-table-column prop="userName" align="center" label="状态" />
          <el-table-column prop="userName" align="center" label="操作" />
        </template>
      </commonTable>
    </el-card>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { memberProject } from "@/api/drawingBed"
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      value: "",
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
    };
  },
  created(){
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        page: 1,
        companyKey:"one-stand"
      }
      memberProject(data).then(res => { })
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleAvatarSuccessCoverImg(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.coverImg = "";
    },
  },
};
</script>

<style></style>