import phpApi from "@/utils/phpApi";

/**全景项目 */
export const ringListData =  params => phpApi.post("/api.php?m=user&act=list_data", params);

/**添加全景图
 */
export const modulePic =  params => phpApi.phpUploadImg("/api.php?module=upload", params,'json');

// /**全景视频列表 */
export const videoList =  params => phpApi.post("/api.php?module=video", params);